const TableUrl = (payload, tableDetails, interAPi) => {
    console.log("tableDetails-------",tableDetails,interAPi)
    if (interAPi && tableDetails.table_url != 'fetchInprogressPaymentList') {
        if(tableDetails.table_url==='searchPolicyList')
            return {"url": `${process.env.VUE_APP_COMMON_SERVICES_TABLE}common/claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`}
        else
        return {
            "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if (tableDetails.table_url == 'fetchStakeDetailsByClaim') {
        return {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    } else if (tableDetails.table_url == 'fetchInprogressPaymentList') {
        return {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_PAYMENT}rcs/payment/api/fetchInprogressPaymentList?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else {
        return {
            "url": `${process.env.VUE_APP_ALLOCATE_TABLE}surveyor/m/api/v1/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
}

const TableConfigUrl = (payload, tableDetails, interAPi = '') => {
    if (interAPi) {
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/${interAPi}/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else if (tableDetails.table_url == 'searchRuntimeFieldsConfig') {
        return {
            "url": `${process.env.VUE_APP_CLAIM_TABLE}claim/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }
    else {
        return {
            "url": `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/user/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
        }
    }

}



const DocumentTableUrl = (payload, tableDetails) => {
    return {
        "url": `${process.env.VUE_APP_DOCUMENT_TABLE}document/api/${tableDetails.table_url}?payload=${payload}&main_filter=${tableDetails.main_filter}`,
    }
}


const setTheme = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}configuration/api/commonConfig`,
        "mutation": "SET_THEME",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const getMenuPages = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/m/master/api/v1/fetchMenuPages`,
        "mutation": "GET_MENU_PAGES",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const GetWigetPages = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/m/master/api/v1/fetchWidgets`,
        "mutation": "GET_WIDEGET_PAGES",
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const DashboardChart = (endPoint, mutation) => {
    const mutation_name = (mutation) ? mutation.toUpperCase() : ''
    return {
        "url": `${process.env.VUE_APP_REPORT}rcs/report/${endPoint}`,
        "mutation": mutation_name ? mutation_name : "CLAIM_SETTLEMENT_WAVE_CHART",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}





const GetFormPage = (getPageCode) => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPage`,
        "mutation": "GET_PAGE_CD_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}
const GetClaimFormPage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPage`,
        "mutation": "GET_FORM_PAGE",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetClaimDetails = () => {

    return {
        "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`,
        "mutation": "GET_PAGE_CD_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const documentDataApi = () => {
    return {
        "url": `${process.env.VUE_APP_REPORT}rcs/report/pdf/api/downloadLossAssesment`,
        "mutation": "GET_DOCUMENT_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}


const GetDocumentDatas = () => {
    return {
        "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/fetchTemplateDocList`,
        "mutation": "GET_CLAIM_DOC_DATAS",
        "module_name": "nbs",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetPageDetails = (getPageCode, mutation) => {
    console.log('cuv66', getPageCode)
    let url = ''
    let claimData=JSON.parse(
        DeocdeString(window.sessionStorage.getItem("sesssion_details"))
      );
      console.log('claimData----',claimData)
    let cattleProd=['5002', '5003','5004', '5007','5008','5009','6622'];
    if(!Number.isNaN(claimData.claimNumber.split('-')[0]) && cattleProd.includes(claimData.claimNumber.split('-')[1])){
    // console.log("this.$store.state.nbs.header_data.productCode",this.$store.state.nbs.header_data.productCode)
    // if(this.$store.state.nbs.header_data.productCode === '6622'){
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchPolicyCoverDetails`;
    // }
    }
    if (getPageCode == 'CLAIMINBOX') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchAction`;
    }
    else if (getPageCode === "neftverifications") {
        url = `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/communication/api/sendNeftVerificationLink`
    }
    return {
        "url": `${url}`,
        "mutation": (mutation) ? mutation : "GET_PAGE_CD_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}
const DeocdeString =(enc_string) =>{
    let _temp = window.atob(enc_string);
    _temp = JSON.parse(_temp);
    return _temp;
}
const GetPageData = (getPageCode, mutation, pageName,source='O') => {
    console.log("line 168 =================================>", getPageCode)
    console.log('getPageCode', getPageCode, mutation, 'br', pageName)
    console.log("source--------",source);
    let tempDetails = JSON.parse(
        DeocdeString(window.sessionStorage.getItem("sesssion_details"))
      );
    console.log("tempDetails--------",tempDetails)
    let url = ''
    if (pageName) {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimHeader`;
    }
    else {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`;
        // mutation = 'GET_HEADER_DATA'; 
    }
    console.log("getPageCode-------172",getPageCode)
    if (getPageCode == 'CLAIMINBOX') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchAction`;
    }
    else if (getPageCode == 'INSCONDET') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/communication/api/fetchInsuredContactDetails`;
    }
    else if (getPageCode == 'INSCKYC' && tempDetails?.claimNumber?.startsWith("21")) {
        url = `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/parties/api/fetchCkycDetails`;
    }
    else if (getPageCode == 'INSLOSSEVEDET') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`;
    }
    else if (getPageCode == 'LOSSEVEVER' || getPageCode == 'INSPOLINFO') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/integration/api/fetchPolicyDetails`;
    }
    else if (getPageCode == 'claiminboxdetails' || getPageCode == 'LOSSEVENTVERIFICATION') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`;
    }
    else if (getPageCode == 'INSUREDPOLICYINFORMATION' || getPageCode == 'INSCOMM') {
        url = `${process.env.VUE_APP_RCS_COMMON}rcs/common/api/fetchPartyDetails`;
    }
    else if (getPageCode == 'INSUREDCOMMUNICATION') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/communication/api/fetchInsuredCommunicationDetails`;
    }
    else if (getPageCode == 'LOSSEVENTVERIFICATION') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`;
    }
    else if (mutation === 'POPUP_PAGE_DATAS') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_DOC_SERVICES}document/api/fetchLORDocListByDocLabel`;
    }
    else if (getPageCode == 'LISTOFREQUIREMENT') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/fetchLOROtherDetails`;
    }
    else if (getPageCode == 'DAMITEMSADJ') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/dia/api/fetchLossEventDetails`;
    }
    else if (getPageCode == 'REQFORAPPROVAL1' || getPageCode == 'REQFORAPPROVAL') {
        url = `${process.env.VUE_APP_RCS_PAYMENT}rcs/payment/api/fetchPaymentDetails`;
    }
    else if (getPageCode == 'EVASUPPPERFO') {
        url = `${process.env.VUE_APP_CONFIG}config/master/api/fetchGradeQuestionnaire`;
    }
    else if (getPageCode == 'damageditemsadjustment' || getPageCode == 'DAMITEMSADJ') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/dia/api/fetchDIADetails`;
    }
    else if (getPageCode == 'claimHeaderData') {
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimHeader`;
    }
    else if (getPageCode == 'CLAIMDATAENTRY') {
        // mutation = 'EDIT_SECTION_DETAILS'
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDataDetails`;
    }
    else if (getPageCode == 'SUPPLIERDETAILS' || getPageCode == 'SELECSUPP') {
        // mutation = 'EDIT_SECTION_DETAILS'
        url = `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/fetchSupplierDetails`;
    }
    else if (getPageCode == 'EVALUATESERVICE') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/fetchServiceRatingDetails`
    }
    else if (getPageCode == 'ALLOCATESURVEYOR') {
        url = `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/fetchAllocateSurveyorDetails`;
    }
    // else if (getPageCode == 'INSCLAIMANTNEFT') {
    //     url = `${process.env.VUE_APP_SURVEYOR}surveyor/rcs/api/fetchNeftDetails`;
    // }
    else if (getPageCode == 'LOSSASSESSMENT') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/calc/api/fetchLossAssessmentDetails`;
    }
    else if (getPageCode == 'FETCHLOSSASSESSMENTFIELDS') {
        url = `${process.env.VUE_APP_CASE_CONFIG_TABLE}config/rcs/calc/api/fetchCalcFields`;
    }
    else if (getPageCode == 'SURVEYORFEECALCULATION') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/fetchPartyDetailsAssociatedByClaim`;
    }
    else if (getPageCode == 'ALLOCATECLAIMDATA') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimDetails`;
    }
    else if (getPageCode == 'PUSHILM') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/ilm/api/fetchTriggerList`;
    }
    else if (getPageCode == 'CLOSINGCLAIM') {
        url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimCloseDetails`;
    }
    // else if(getPageCode == 'SENDNEFTVERIFICATION'){
    //     console.log("sendNeftVerificationLink 253 =================================>")
    //     url = `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/communication/api/sendNeftVerificationLink`;
    // }
    else if(getPageCode == 'SENDNEFTVER'){
        url = `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/communication/api/sendNeftVerificationLink`;
    }

    return {
        "url": `${url}`,
        "mutation": (mutation) ? mutation : "GET_PAGE_CD_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const GetViewNeftPopupData = () => {
    return {
        "url": `${process.env.VUE_APP_ALLOCATE_TABLE}surveyor/rcs/api/fetchNeftDetails`,
        "mutation": "GET_NEFT_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetMultiRowTableReponse = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/rcs/calc/api/fetchCalculatedDataDetails`,
        "mutation": "GET_MULTI_ROW_TABLE_DATA",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetMultiRowTableFields = () => {
    return {
        "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/calc/api/fetchCalcValue`,
        "mutation": "MULTI_ROW_TABLE_ALL_DATA",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}
const SaveMultiRowTableData = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/rcs/calc/api/saveCalcDetails`,
        "mutation": "",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}

const GetNeftDocumentData = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/supplier/api/fetchLORDocDetails`,
        "mutation": "GET_NEFT_DOCUMENT_DETAILS",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetRemoveClaimantData = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/parties/api/saveClaimantDetails`,
        "mutation": "REMOVE_CLAIMANT",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}

const adminUploadMaster = () => {
    return {
        "url": `${process.env.VUE_APP_DOCUMENT_TABLE}document/admin/api/uploadMasterDocumentDetails`,
        "mutation": "REMOVE_CLAIMANT",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}

const GeAddClaimantData = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/parties/api/saveClaimantDetails`,
        "mutation": "ADD_CLAIMANT",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}



const GetCurrencyRateData = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM_TABLE}rcs/claim/reserve/api/fetchCurrencyValue`,
        "mutation": "FETCH_CURRENCY_RATE",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {},
        "send_payload": true,
        "encryption": "true",
    }
}

const GetPageListData = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPage`,
        "mutation": "GET_MULTI_SECTION_PAGE_LIST",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetPageFields = (module) => {
    console.log("In GetPageFields",module)
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "module_name": module ? module : "",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetMainPageFields = (mutatioName) => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": (mutatioName) ? mutatioName : "GET_MULTI_SECTION_PAGE_FIELDS",
        "module_name": "nbs",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetPopupPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_POP_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "encryption": "true",
        "module_name": "multi_data_from",
        "root_access": "yes",
        "root_mutation_text": "GET_MD_ROOTSTATE_DETAILS",
    }
}

const PopUpPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "requests": {}
    }
}

const GetMultiReportsPageFields = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/api/fetchFormPageField`,
        "mutation": "GET_MULTI_SECTION_PAGE_FIELDS",
        "notify_message": "Success",
        "encryption": "true",
        "module_name": "multi_data_from",
        "root_access": "yes",
        "root_mutation_text": "GET_MD_ROOTSTATE_DETAILS",
        "requests": {}
    }
}



const GetDropDownAPIs = (fieldName) => {
    console.log("cuv11", fieldName)
    let apiHolder = [{
        "workFlowType": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_PAYMENT}rcs/payment/api/fetchWorkFlow`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "partnerAs": {
            "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchClaimantMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "country": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/api/fetchCountryMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "mainStatus": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchClaimStatus`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "isGSTPayable":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCommonData`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "paymentMode":{
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCommonData`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimStatus": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchClaimStatusMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "causeOffLoss": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCauseOffLoss`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "productCode": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchproductList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "lineOfBusiness": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLOBList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimLocationCode": {
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserLocationCodeList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "claimHandler": {
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchAllClaimHandlerNameList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "diaApprovalAuthority": {
            "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchAllClaimHandlerNameList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "vas": {
            "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchValueAddedServices`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "subStatus": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchClaimSubStatus`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "deficiencyReason": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/config/api/fetchDeficiencyReason`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "productCode": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchproductList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "eventCode": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/config/api/fetchEventCodeList`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "zoneCode": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchZoneCode`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "causeOfLoss": {
            "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchCauseOffLoss`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "pendingAgeBand": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchPendingAgeBand`,
            "mutation": "GET_LOOKUP_VALUES",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "reportType": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_REPORT}rcs/report/api/fetchReportTypes`,
            "mutation": "GET_LOOKUP_VALUES",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "state": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/api/fetchStateMaster`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "payableAt": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_COMMON}rcs/common/api/fetchDistrictByState`,
            "mutation": "GET_MASTER_LOOKUP",
            "module_name": "nbs",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "micrCode": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchMicrCodeByIfsc`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "partyCode": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/getClaimantNameByClaimNumber`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "diaStatus": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/dia/api/fetchDIAStatusByApproveAuthority`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "diaApprovalAuthority": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_RCS_CLAIM}rcs/claim/api/fetchSalvageApprovalLimitByUserId?`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "closingReason": {
            "url": `${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchClosingReason`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "deficiencyResons": {
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONFIG}config/rcs/config/api/fetchDeficiencyReason`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},
        },
        "locationOfLossOceanVoyage":{
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchMarineVoyages`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},            
        },
        "portOfLossOceanVoyage":{
            "url": `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchPortNames`,
            "mutation": "GET_MASTER_LOOKUP",
            "notify_message": "Success",
            "send_payload": true,
            "encryption": "true",
            "requests": {},            
        }
    }]
    console.log("eueeeee", apiHolder[0][fieldName])
    if (fieldName.includes('__')) {
        let splitName = fieldName.split('__')
        if (splitName && splitName.length && (splitName.length > 1) && (typeof apiHolder[0][splitName[1]] != "undefined")) {
            return apiHolder[0][splitName[1]]
        }
    }
    else if (typeof apiHolder[0][fieldName] != "undefined") {
        return apiHolder[0][fieldName]
    }
    else {
        return ''
    }
}

const LookupByType = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "module_name": "nbs",
        "requests": {}
    }
}
const NewLookupByType = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "requests": {}
    }
}

const LookupByTypeMulti = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/fetchLookUpByType`,
        "mutation": "GET_LOOKUP_VALUES",
        "send_payload": true,
        "notify_message": "Success",
        "module_name": "nbs",
        "requests": {}
    }
}



const MasterLookupByType = (masterAPI) => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/master/api/${masterAPI}`,
        "mutation": "GET_MASTER_VALUES",
        "notify_message": "Success",
        "module_name": "nbs",
        "requests": {}
    }
}

const UserSummaryCount = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/report/api/fetchClaimSummaryCount`,
        "mutation": "GET_SUMMARY_COUNT",
        "notify_message": "Success",
        "requests": {}
    }
}


const GetPageAPIData = (pageame) => {
    let getpagename = (pageame) ? pageame.toLowerCase() : ''
    let apiData = {
        'inprogressclaimsur': {
            "url": `${process.env.VUE_APP_COMMON}surveyor/m/communication/api/v1/fetchCommunicationDetails`,
            "mutation": "GET_PAGE_DETAILS",
        }
    }


    let pageUrl = {};
    if (apiData[getpagename]) {
        pageUrl = apiData[getpagename]
    }

    let tempData = {
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {},
        ...pageUrl
    }

    if (pageame) {
        return tempData;
    }
    else {
        return '';
    }

}

const SavePageData = (pageame) => {
    console.log('cuv45', pageame)
    // Both saveOnlineDocs and submitClaimantNeft shared a common endpoint, for payload functionality which I have now distinguished
    let apiData = {
        'uploadDocument': {
            "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'gstpayable': {
            "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'saveReserveDetails': {
            "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/reserve/api/saveReserveDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'saveInsuredLORDocList':{
            "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'submitClaimantNeft': {
            "url": `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/uploadDocument`,
            "mutation": "SAVE_RESULTS",
        },
        'saveOnlineDocs': {
            "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/saveInsuredLORDocList`,
            "mutation": "SAVE_RESULTS",
        },
        "lorDocumentDetails": {
            "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/supplier/api/saveInsuredLORDocList`,
            "mutation": "SAVE_RESULTS",
        },
        'saveNeftDetails': {
            "url": `${process.env.VUE_APP_SURVEYOR}surveyor/rcs/api/saveNeftDetails`,
            "mutation": "SAVE_RESULTS",
        },
        'saveLossAssesment': {
            "url": `${process.env.VUE_APP_RCS_COMMON}rcs/common/calc/api/fetchCalcValue`,
            "mutation": "SAVE_RESULTS",
        },

    }

    let pageUrl = {};
    if (apiData[pageame]) {
        pageUrl = apiData[pageame]
    }

    if (pageUrl && Object.keys(pageUrl).length > 0) {
        return {
            "url": ``,
            "mutation": "SAVE_RESULTS",
            "notify_message": "Success",
            "success_message": "",
            "requests": {},
            ...pageUrl
        }
    }
    else {
        return false;
    }
}


const SaveFlashMessage = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/home/api/saveFalshMessage`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveClientConfiguration = () => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/saveRuntimeFields`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const SaveMenuAccess = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/saveUserAccess`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}





const GetTablePageData = (pageName) => {
    let url = '';
    if (pageName == 'userTable') {
        url = `${process.env.VUE_APP_CONFIG}config/user/api/userSearch`;
    }
    else if (pageName == 'flashMessage') {
        url = `${process.env.VUE_APP_CONFIG}config/home/api/fetchFalshMessageList`;
    }
    else if (pageName == 'clientManagement') {
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyUserDetails`;
    }
    else if (pageName.trim() == 'userHierarchy') {
        url = `${process.env.VUE_APP_CONFIG}config/user/api/fetchHierarchyPreference`;
    }
    else if (pageName.trim() == 'clientFields1') {
        url = `${process.env.VUE_APP_CONFIG}config/user/api/searchRuntimeFieldsConfig`;
    }

    return {
        "url": `${url}`,
        "mutation": "GET_TABLE_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}

// const SavePageData = (pageame) => {
//     let apiData ={
//         'allocation': {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'communicationDetails': {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveCommunicationDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'invoiceDetails': {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveInvoiceDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'uploadDocument': {
//             "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'neftUploadDocument': {
//             "url": `${process.env.VUE_APP_DOC_SERVICES}document/api/uploadDocument`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'saveAction': {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/saveAction`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'surveyDetails': {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'updateneft':{
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveNeftDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'saveClaimPanDetails':{
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/payment/api/v1/saveClaimantPanDetails`,
//             "mutation": "SAVE_RESULTS",
//         },
//         'claimReviewDetails':{
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveClaimReview`,
//             "mutation": "SAVE_RESULTS",
//         },
//         "serviceDetails":{
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/communication/api/v1/saveServiceRatingDetails`,
//             "mutation": "SAVE_RESULTS",
//         }
//     }

//     let pageUrl = {};
//     if(apiData[pageame]){
//         pageUrl = apiData[pageame]
//     }

//     if(pageUrl && Object.keys(pageUrl).length>0){
//         return {
//             "url": `${process.env.VUE_APP_SURVEYOR}surveyor/m/api/v1/allocateSurveyorDetails2`,
//             "mutation": "SAVE_RESULTS",
//             "notify_message": "Success",
//             "success_message": "",
//             "requests": {},
//             ...pageUrl
//         }
//     }
//     else{
//         return false;
//     }
// }

const FetchPageData = (pageName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${pageName}`,
        "mutation": "GET_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}
const FetchClaimPageData = (pageName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${pageName}`,
        "mutation": "GET_CLAIM_PAGE_DETAILS",
        "notify_message": "Success",
        "requests": {}
    }
}


const DownloadDocument = (end_point, reqtype) => {
    console.log('cuv222', reqtype, end_point)
    let base_url = ''
    if (reqtype == 'POST') {
        base_url = `${process.env.VUE_APP_REPORT}rcs/report/pdf/api/${end_point}`
    }
    else if (reqtype == 'GET') {
        base_url = `${process.env.VUE_APP_DOWNLOAD_SERVICES}document/api/${end_point}`
    }
    return {
        "url": base_url,
        "mutation": "GET_DOWNLOAD_DOCUMENT",
        "send_payload": true,
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }
}

const adminDownloadDoc = (end_point, reqtype) => {
    let base_url = ''
    if (reqtype == 'GET') {
        base_url = `${process.env.VUE_APP_API_URL}/${process.env.VUE_APP_CONFIG}config/admin/api/${end_point}`
    }
    return {
        "url": base_url,
        "mutation": "GET_DOWNLOAD_DOCUMENT",
        "send_payload": true,
        "notify_message": "Success",
        "encryption": "true",
        "requests": {}
    }

}

const SaveForm = (apiName) => {
    return {
        "url": `${process.env.VUE_APP_CLAIM}claim/api/${apiName}`,
        "mutation": "SAVE_RESULTS",
        "notify_message": "Success",
        "success_message": "",
        "requests": {}
    }
}

const claimSettlementCycleAPI = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/rcs/master/api/fetchClaimMileStone`,
        "mutation": "GET_CYCLE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}

const claimSettlementCycleAPIStatus = () => {
    return {
        "url": `${process.env.VUE_APP_RCS_CLAIM}rcs/claim/analytics/api/fetchClaimCycleDetails`,
        "mutation": "GET_CYCLE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}

const UserProfile = () => {
    return {
        "url": `${process.env.VUE_APP_CONFIG}config/user/api/fetchUserProfile`,
        "mutation": "GET_USER_PROFILE",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetAllowedActions = () => {
    return {
        "url": `${process.env.VUE_APP_RCS_PAYMENT}rcs/payment/wf/api/fetchAllowedActions`,
        "mutation": "GET_ALLOWED_ACTIONS",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetPincodeCode = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/master/api/fetchLocationByPincode`,
        "mutation": "GET_PINCODE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}
const GetClaimPincodeCode = () => {
    return {
        "url": `${process.env.VUE_APP_COMMON}common/master/api/fetchLocationByPincode`,
        "mutation": "GET_CLAIM_PINCODE_DATA",
        "notify_message": "Success",
        "requests": {}
    }
}
export {
    setTheme,
    getMenuPages,
    GetFormPage,
    UserProfile,
    GetPageFields,
    LookupByType,
    FetchPageData,
    MasterLookupByType,
    GetDropDownAPIs,
    TableUrl,
    DocumentTableUrl,
    SaveForm,
    DownloadDocument,
    TableConfigUrl,
    PopUpPageFields,
    UserSummaryCount,
    GetTablePageData,
    SaveFlashMessage,
    SaveMenuAccess,
    SaveClientConfiguration,
    GetMultiReportsPageFields,
    LookupByTypeMulti,
    GetPopupPageFields,
    SavePageData,
    GetPageAPIData,
    GetWigetPages,
    GetPageData,
    GetMainPageFields,
    GetAllowedActions,
    GetClaimDetails,
    GetPageListData,
    DashboardChart,
    claimSettlementCycleAPI,
    claimSettlementCycleAPIStatus,
    documentDataApi,
    GetDocumentDatas,
    GetPageDetails,
    GetViewNeftPopupData,
    GetNeftDocumentData,
    GetRemoveClaimantData,
    GeAddClaimantData,
    GetCurrencyRateData,
    GetMultiRowTableReponse,
    GetMultiRowTableFields,
    SaveMultiRowTableData,
    adminDownloadDoc,
    adminUploadMaster,
    GetPincodeCode,
    GetClaimFormPage,
    FetchClaimPageData,
    GetClaimPincodeCode,
    NewLookupByType
}